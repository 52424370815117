<template>
	<div>
		<div class="ft20 cl-main ftw600">抢购管理</div>

		<div class="mt20">
			<div class="form-search-box ">
				<a-form layout="inline">
					<a-form-item label="项目简称">
						<a-input v-model="search.abbreviation" placeholder="请输入项目简称"></a-input>
					</a-form-item>

					<a-form-item label="项目分类">
						<div>
							<a-select style="width: 120px" placeholder="全部分类" v-model="search.project_type_id">
								<a-select-option :value="0">全部分类</a-select-option>
								<a-select-option :value="item.project_type_id" :key="index"
									v-for="(item,index) in type_list">{{item.name}}</a-select-option>
							</a-select>
						</div>
					</a-form-item>

					<a-form-item label="销量">
						<a-input v-model="search.bg_take_count" style="width: 80px"></a-input>
						-
						<a-input v-model="search.end_take_count" style="width: 80px"></a-input>
					</a-form-item>
					<a-form-item>
						<a-button @click="searchAct" type="primary">查询</a-button>
						<a-button @click="cancelAct" class="ml10">取消</a-button>
					</a-form-item>
				</a-form>
			</div>
		</div>

		<div class="mt20">
			<div class="pd30 bg-w " style="min-height: 800px;">
				<div>
					<a-button type="primary" icon="plus" @click="addSnapUpAct()">添加抢购</a-button>
				</div>

				<div class="mt20 flex alcenter">
					<a-radio-group v-model="search.status" button-style="solid" @change="changeStatus">
						<a-radio-button :value="1">
							上架中
						</a-radio-button>
						<a-radio-button :value="-1">
							已下架
						</a-radio-button>
					</a-radio-group>

					<div class="ml40 flex alcenter">
						<div>是否秒杀</div>

						<a-radio-group name="radioGroup" class="ml15" v-model="search.is_flash_sale"
							@change="changeFlash">
							<a-radio :value="1">
								是
							</a-radio>
							<a-radio :value="0">
								否
							</a-radio>
						</a-radio-group>
					</div>
				</div>

				<div class=" mt20">
					<div class="wxb-table-gray">
						<a-table rowKey="snap_up_id" :columns="columns" :pagination="pagination"
							@change="handleTableChange" :data-source="datas" :loading="loading">

							<div slot="project" slot-scope="project,record">
								<div class="flex alcenter center" v-if="project != null">
									<img :src="project.cover_img" class="projetc-index-cover-img" />
									<div class="ml5" style="text-align: left;">
										<div>{{project.abbreviation}}</div>
									</div>
								</div>

								<div v-else>-</div>
							</div>

							<div class="flex alcenter center" slot="type" slot-scope="type,record">
								{{type != null ? type.name : '暂无分类'}}
							</div>

							<div slot="flash_sale" slot-scope="record">
								<div class="flex alcenter "
									v-if="record.is_flash_sale==1 && record.is_flash_sale_expire==0">
									<div class="snap-up-flash-sale-tag ft14 ftw400 cl-w" style="background: #FF6600;">秒杀
									</div>
									<div class="ml10 ft14 ftw400 cl-black">秒杀价 ¥{{record.flash_price}}</div>
								</div>
								<div class="flex alcenter " v-else>
									<div class="snap-up-flash-sale-tag ft14 ftw400 cl-w" style="background: #DCDFE6;">秒杀
									</div>
								</div>
							</div>

							<div class="flex alcenter center" slot="vip_price" slot-scope="vip_price,record">
								¥{{vip_price}}
							</div>

							<div class="flex alcenter center" slot="market_price" slot-scope="record">
								¥{{record.project ? record.project.market_price : '0'}}
							</div>

							<template slot="action" slot-scope="record,index">
								<div class="flex center">
									<a-dropdown placement="bottomRight">
										<span class="more-act">
											<i class="iconfont iconmore_gray"></i>
										</span>
										<a-menu slot="overlay">
											<a-menu-item>
												<a class="menu-act" href="javascript:;" @click="editSnapUpAct(record)">
													<i class="iconfont ft14 iconedit"></i>
													<span class="ml10">编辑</span>
												</a>
											</a-menu-item>
											<a-menu-item v-if="record.status==1">
												<a class="menu-act" href="javascript:;" @click="undercarriage(record)">
													<i class="iconfont ft14 iconxiajia"></i>
													<span class="ml10">下架</span>
												</a>
											</a-menu-item>
											<a-menu-item v-if="record.status==-1">
												<a class="menu-act" href="javascript:;" @click="grounding(record)">
													<i class="iconfont ft14 iconshangjia"></i>
													<span class="ml10">上架</span>
												</a>
											</a-menu-item>
											<a-menu-item v-if="record.status==-1">
												<a class="menu-act" href="javascript:;" @click="del(record)"> 
													<i class="iconfont ft14 icondelete"></i>
													<span class="ml10">删除</span>
												</a>
											</a-menu-item>

										</a-menu>
									</a-dropdown>
								</div>
							</template>
						</a-table>
					</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
	import {
		listMixin
	} from '../../common/mixin/list.js';
	export default {
		mixins: [listMixin],
		data() {
			return {
				loading: false,
				pagination: {
					current: 1,
					pageSize: 10, //每页中显示10条数据
					total: 0,
				},
				search: {
					abbreviation: '',
					project_type_id: 0,
					bg_take_count: '',
					end_take_count: '',
					status: 1,
					is_flash_sale: 0,
				},
				type_list: [],
				columns: [

					{
						title: '抢购编号',
						dataIndex: 'snap_up_id',
						align: 'center'
					},
					{
						title: '抢购名称',
						dataIndex: 'project',
						align: 'center',
						width: 300,
						scopedSlots: {
							customRender: 'project'
						}
					},
					{
						title: '所属分类',
						dataIndex: 'type',
						align: 'center',
						scopedSlots: {
							customRender: 'type'
						}
					},
					{
						title: '是否秒杀',
						key: 'flash_sale',
						align: 'center',
						width: 200,
						scopedSlots: {
							customRender: 'flash_sale'
						}
					},
					{
						title: '会员价',
						dataIndex: 'vip_price',
						align: 'center',
						scopedSlots: {
							customRender: 'vip_price'
						}
					},
					{
						title: '项目门市价',
						key: 'market_price',
						align: 'center',
						scopedSlots: {
							customRender: 'market_price'
						}
					},
					{
						title: '已售',
						dataIndex: 'take_count',
						align: 'center',
						ellipsis: true
					},
					{
						title: '创建时间',
						dataIndex: 'add_time_format',
						align: 'center',
						ellipsis: true
					},
					{
						title: '操作',
						key: 'action',
						align: 'center',
						scopedSlots: {
							customRender: 'action'
						}
					}
				],
				datas: [],
			}
		},
		methods: {
			getLists() {
				if (this.loading == true) return;
				this.loading = true;
				this.$http.api('admin/getSnapupList', {
					limit: this.pagination.pageSize,
					page: this.pagination.current,
					abbreviation: this.search.abbreviation,
					project_type_id: this.search.project_type_id,
					bg_take_count: this.search.bg_take_count,
					end_take_count: this.search.end_take_count,
					status: this.search.status,
					is_flash_sale: this.search.is_flash_sale,
				}).then(res => {
					this.pagination.total = res.total;
					this.datas = res.list;
					if (res.type_list) {
						this.type_list = res.type_list;
					}
					this.loading = false;
				}).catch(res => {
					this.loading = false;
				})
			},
			
			undercarriage(record){
				this.$confirm({
					title:'确认下架这个抢购吗？',
					okText:"确定",
					okType:"danger",
					cancelText:"取消",
					onOk : ()=>{
						return new Promise((resolve,reject)=>{
							this.$http.api('admin/undercarriageSnapup',{
								snap_up_id:record.snap_up_id,
							}).then(res=>{
								this.$message.success('下架成功');
								this.getLists();
							}).catch(res=>{
								console.log(res);
							}).finally(()=>{
								resolve();
							})
						})
					}
				})
			},
			
			grounding(record){
				this.$confirm({
					title:'确认上架这个抢购吗？',
					okText:"确定",
					okType:"danger",
					cancelText:"取消",
					onOk : ()=>{
						return new Promise((resolve,reject)=>{
							this.$http.api('admin/groundingSnapup',{
								snap_up_id:record.snap_up_id,
							}).then(res=>{
								this.$message.success('上架成功');
								this.getLists();
							}).catch(res=>{
								console.log(res);
							}).finally(()=>{
								resolve();
							})
						})
					}
				})
			},
			
			del(record){
				this.$confirm({
					title:'确认删除这个抢购吗？',
					okText:"确定",
					okType:"danger",
					cancelText:"取消",
					onOk : ()=>{
						return new Promise((resolve,reject)=>{
							this.$http.api('admin/delSnapup',{
								snap_up_id:record.snap_up_id,
							}).then(res=>{
								this.$message.success('删除成功');
								this.getLists();
							}).catch(res=>{
								console.log(res);
							}).finally(()=>{
								resolve();
							})
						})
					}
				})
			},
			
			addSnapUpAct() {
				this.$router.push('/project/snapup/edit');
			},
			editSnapUpAct(record) {
				this.$router.push('/project/snapup/edit?id=' + record.snap_up_id);
			},

			changeFlash() {
				this.pagination.current = 1;
				this.getLists();
			},

			changeStatus() {
				this.pagination.current = 1;
				this.getLists();
			},
			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getLists();
			},
		}
	}
</script>

<style>
	.projetc-index-cover-img {
		width: 80px;
		height: 60px;
	}

	.snap-up-flash-sale-tag {
		width: 40px;
		height: 20px;
		border-radius: 3px;
	}
</style>
